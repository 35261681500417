
const multiInput = document.querySelector('multi-input'); 
const values = document.querySelector('#values'); 

$('#get').onclick = function () {
  if (multiInput.getValues().length > 0) {
    values.textContent = `Got ${multiInput.getValues().join(' and ')}!`;
  } else {
    values.textContent = 'Got noone  :`^(.'; 
  }
}

